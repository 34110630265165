import type { FieldErrors, FieldValues, GlobalError, Path } from "react-hook-form";

/**
 * NOTE: errorsオブジェクトから指定したプロパティのバリデーションメッセージを取得
 * @通常のパターン
 * errors["initialPassword"].message
 * @配列のパターン
 * errors["users"][0]["email"].message
 * @sample
{
  "initialPassword": {
      "message": "8文字以上の文字列である必要があります。",
      "type": "too_small",
      "ref": {
          "name": "initialPassword"
      }
  },
  "users": [
      {
          "email": {
              "message": "メールアドレスの形式で入力してください。",
              "type": "invalid_string",
              "ref": {
                  "name": "users.0.email"
              }
          },
          "name": {
              "message": "1文字以上の文字列である必要があります。",
              "type": "too_small",
              "ref": {
                  "name": "users.0.name"
              }
          }
      }
  ]
} 
 * */
export const getErrorMessage = <T extends FieldValues>(
  errors: FieldErrors<T>,
  name: Path<T> & (string | undefined)
): string => {
  if (typeof name !== "string") return "";
  if (!name.includes(".")) {
    const m = errors?.[name]?.message;
    if (typeof m === "string") {
      return m;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: GlobalError = name.split(".").reduce<{ [key: string]: any }>((res, props) => {
    return res?.[props];
  }, errors);

  return obj?.message ?? "";
};
