import type { SelectProps } from "@chakra-ui/react";
import { Select as CSelect } from "@chakra-ui/react";
import type { VFC } from "react";
import type { SelectOption } from "src/types";

type Props = SelectProps & {
  option: SelectOption[];
};

export const Select: VFC<Props> = (props) => {
  const { option, ...selectProps } = props;
  return (
    <CSelect bg="white" textAlign="center" {...selectProps}>
      {option.map((item) => (
        <option key={item.value} value={item.value} selected={props.value === item.value}>
          {item.text}
        </option>
      ))}
    </CSelect>
  );
};
