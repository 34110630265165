import type { SalesOffice, User } from "src/graphql/API";
import type { SelectOption } from "src/types";

export const toSalesOfficeOptions = (items: SalesOffice[] | null): SelectOption[] => {
  const options = [{ value: "", text: "" }];

  if (!items) return options;
  return [
    ...options,
    ...items.map((item) => ({
      value: item.code,
      text: item.name,
    })),
  ];
};

export const toCustomerOptions = (items: SalesOffice | null, enableEmptyOption = true): SelectOption[] => {
  const options = enableEmptyOption ? [{ value: "", text: "" }] : [];

  if (!items?.customers) return options;
  return [
    ...options,
    ...items.customers.items
      .filter((v): v is NonNullable<typeof v> => !!v)
      .map((item) => ({
        value: item.code,
        text: item.name,
      })),
  ];
};

export const toEmployeesBySalesOfficeCode = (items: SalesOffice[] | null, code: string): User[] => {
  if (!items) return [];

  const found = items.find((v) => v.code === code);

  if (!found) return [];
  return found.employee?.items.filter((v): v is NonNullable<typeof v> => !!v) ?? [];
};
