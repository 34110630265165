import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import type { ComponentProps, VFC } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Select } from "src/components/ui/form/Select";
import { getErrorMessage } from "src/components/ui/form/util";

type Props = ComponentProps<typeof Select> & {
  label: string;
  error?: string;
  disabledHelperText?: boolean;
};
export const SelectField: VFC<Props> = (props) => {
  const { label, error = "", disabledHelperText = false, ...selectProps } = props;
  return (
    <FormControl>
      <FormLabel fontSize="sm" m="0">
        {label}
        <Select {...selectProps} />
      </FormLabel>
      {!disabledHelperText && (
        <FormHelperText color="red.500" h="4" mt="0" pl="4">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

type RhfProps<T extends FieldValues> = UseControllerProps<T> & Props;
export const RhfSelectField = <T extends FieldValues>(props: RhfProps<T>) => {
  const { control, name, defaultValue, rules, ...others } = props;
  const {
    field,
    formState: { errors },
  } = useController<T>({ control, name, defaultValue, rules });

  return <SelectField error={getErrorMessage(errors, name)} {...field} {...others} />;
};
