import type { InputGroupProps } from "@chakra-ui/react";
import { Input as CInput, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import type { ComponentProps, ReactNode, VFC } from "react";
import { Icon } from "src/components/ui/Icon";

type Props = ComponentProps<typeof CInput> & {
  leftIcon?: ReactNode;
  inputGroupProps?: InputGroupProps;
  rightIcon?: ReactNode;
  clear?: () => void;
};

export const Input: VFC<Props> = (props) => {
  const { leftIcon, rightIcon, inputGroupProps = {}, clear, ...inputProps } = props;
  return (
    <InputGroup {...inputGroupProps}>
      {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
      <CInput bg="white" {...inputProps} />
      {clear && inputProps.value ? (
        <InputRightElement>
          <Icon name="close" w="3" h="3" onClick={clear} cursor="pointer" />
        </InputRightElement>
      ) : (
        rightIcon && <InputRightElement>{rightIcon}</InputRightElement>
      )}
    </InputGroup>
  );
};
