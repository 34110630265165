import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import type { ComponentProps, VFC } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Input } from "src/components/ui/form/Input";
import { getErrorMessage } from "src/components/ui/form/util";

type Props = ComponentProps<typeof Input> & {
  label: string;
  error?: string;
};

export const InputField: VFC<Props> = (props) => {
  const { label, error = "", ...inputProps } = props;
  return (
    <FormControl>
      <FormLabel fontSize="sm" m="0">
        {label}
        <Input {...inputProps} />
      </FormLabel>
      <FormHelperText color="red.500" h="4" mt="0" pl="4">
        {error}
      </FormHelperText>
    </FormControl>
  );
};

type RhfProps<T extends FieldValues> = UseControllerProps<T> & Props;
export const RhfInputField = <T extends FieldValues>(props: RhfProps<T>) => {
  const { control, name, defaultValue, rules, ...others } = props;

  const {
    field,
    formState: { errors },
  } = useController<T>({ control, name, defaultValue, rules });

  return <InputField {...field} error={getErrorMessage(errors, name)} {...others} />;
};
