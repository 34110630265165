import type { BackgroundProps, ModalContentProps } from "@chakra-ui/react";
import { Modal as CModal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import type { ComponentProps, ReactNode, VFC } from "react";

type Props = ModalContentProps & {
  isOpen: boolean;
  onClose: () => void;
  enableCloseButton?: boolean;
  children: ReactNode;
  autoFocus?: boolean;
  bg?: BackgroundProps["bg"];
  modalSize?: ComponentProps<typeof CModal>["size"];
  closeOnOverlayClick?: boolean;
  headerTitle?: string;
};

export const BaseModal: VFC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    enableCloseButton = false,
    closeOnOverlayClick = true,
    children,
    autoFocus = false,
    bg = "white",
    modalSize = undefined,
    headerTitle = "",
    ...modalContentProps
  } = props;

  return (
    <CModal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={autoFocus}
      size={modalSize}
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent mx="2" p="4" bg={bg} {...modalContentProps}>
        <ModalHeader p="0">
          {headerTitle}
          {enableCloseButton && <ModalCloseButton />}
        </ModalHeader>
        {children}
      </ModalContent>
    </CModal>
  );
};
